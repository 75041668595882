import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { Section, Text } from '../../components/Core';
import aboutImage from '../../assets/image/jpeg/aboutus.jpg';
import { device } from '../../utils';

const SectionStyled = styled(Section)`
    background-image: url(${aboutImage});
    background-size: cover;
    background-position: center;

    @media ${device.lg} {
        background-position: center;
    }
`;

const Hero = ({ title, children, ...rest }) => {
    return (
        <>
            <SectionStyled
                pt={['120px!important', null, '300px!important']}
                pb={['120px!important', null, '300px!important']}
                {...rest}
            >
                <Container>
                    <Row>
                        <Col>
                            <div>
                                {/* <Title variant="hero" className="mb-0 text-center">
                                    {title}
                                </Title> */}
                                <Text>{children}</Text>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </SectionStyled>
        </>
    );
};
export default Hero;
