import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { Title, Section, Box, Text } from '../../components/Core';

import imgU1 from '../../assets/image/png/I.A.K.png';
import imgU2 from '../../assets/image/png/ZAK.png';
import imgU3 from '../../assets/image/png/H.A.K.png';
import imgU4 from '../../assets/image/png/Vlad-Danciu.png';
import imgU5 from '../../assets/image/png/Ovidiu.png';
import imgU6 from '../../assets/image/png/Ahmed_Munir.png';
import imgU7 from '../../assets/image/png/Usman_Ali.png';
import imgU8 from '../../assets/image/png/Arslan_lodhi.png';
import imgU9 from '../../assets/image/png/Haris_Ali.png';

const CardImage = styled.div`
    max-width: 201px;
    min-width: 201px;
    min-height: 200px;
    max-height: 200px;
    overflow: hidden;
    border-radius: 500px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 29px;
`;

const TeamCard = ({ userImg, title, children, ...rest }) => (
    <Box className="text-center" pt="15px" px="30px" borderRadius={10} mb={4} {...rest}>
        <CardImage>
            <img src={userImg} className="img-fluid" alt="" />
        </CardImage>
        <div className="text-center">
            <Title variant="card" fontSize="24px" letterSpacing={-0.75} my={1}>
                {title}
            </Title>
            <Text fontSize={2} lineHeight={1.75}>
                {children}
            </Text>
        </div>
    </Box>
);

const Team = () => (
    <>
        {/* <!-- Team section --> */}
        <Section className="position-relative">
            <Container>
                <Row className="justify-content-center">
                    <Col lg="6" className="text-center pb-3">
                        <div className="">
                            <Title>Meet the team</Title>
                            <Text>
                                The group, established by our outstanding strong core team, is
                                excelling by the experience of astounding industry leaders.
                            </Text>
                        </div>
                    </Col>
                </Row>
                <Row className="align-items-center justify-content-center">
                    <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
                        <TeamCard userImg={imgU1} title="Imran Ali Khan">
                            CEO, EMPG
                        </TeamCard>
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
                        <TeamCard userImg={imgU2} title="Zeeshan Ali Khan">
                            CEO EMPG Pakistan
                        </TeamCard>
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
                        <TeamCard userImg={imgU3} title="Haider Ali Khan">
                            Head of EMPG MENA
                        </TeamCard>
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
                        <TeamCard userImg={imgU4} title="Vlad Danciu">
                            Head of Engineering, EMPG
                        </TeamCard>
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
                        <TeamCard userImg={imgU5} title="Ovidiu Sabou">
                            CTO, EMPG
                        </TeamCard>
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
                        <TeamCard userImg={imgU6} title="Ahmed Munir">
                            Director Engineering
                        </TeamCard>
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
                        <TeamCard userImg={imgU7} title="Usman Ali">
                            Director Product
                        </TeamCard>
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
                        <TeamCard userImg={imgU8} title="Arsalan Lodhi">
                            Director Engineering
                        </TeamCard>
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
                        <TeamCard userImg={imgU9} title="Haris Khan">
                            Director Engineering
                        </TeamCard>
                    </Col>
                </Row>
            </Container>
        </Section>
    </>
);

export default Team;
