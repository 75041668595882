import React from 'react';

import PageWrapper from '../components/PageWrapper';
import Hero from '../sections/about/Hero';
import Content from '../sections/about/Content';
import Team from '../sections/about/Team';
import Values from '../sections/about/Values';
import Cta from '../sections/about/CTA';
import { Helmet } from 'react-helmet';

const About = () => {
    return (
        <>
            <PageWrapper footerDark>
                <Helmet>
                    <title>About Us - EMPG LABS</title>
                    <meta
                        name="description"
                        content="EMPG LABS is the tech arm of the group that is building scalable solutions and handles millions of users across the globe. We have a tech team of 500+ members across the globe."
                    />
                    <meta property="og:url" content="https://www.empglabs.com/about-us" />
                    <meta property="og:title" content="About Us - EMPG LABS" />
                    <meta
                        property="og:description"
                        content="EMPG LABS is the tech arm of the group that is building scalable solutions and handles millions of users across the globe. We have a tech team of 500+ members across the globe."
                    />
                    <meta data-react-helmet="true" property="og:image" content="" />
                    <link rel="canonical" href="https://www.empglabs.com/about-us" />
                </Helmet>
                <Hero title="About us"></Hero>
                <Content />
                <Team />
                <Values />
                <Cta />
            </PageWrapper>
        </>
    );
};
export default About;
